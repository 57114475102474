.products {
  @extend .row;
  align-items: stretch;
}
.product-miniature {
  @extend .col-6;
}
@include media-breakpoint-desktop {
  .product-miniature {
    @include make-col(4);
  }

  .layout-full-width .product-miniature {
    @include make-col(3);
  }
}

@media (max-width: 320px) {
  .product-miniature {
    @include make-col(12);
  }
}

.slick-slide .product-miniature {
  max-width: 100%;
}
/* Product miniature*/
.card-product.card {
  min-width: 17.25rem;
  height: 100%;
  position: relative;
  background-color: $pm-bg-color;
  border-width: $pm-border-width;
  border-color: $pm-border-color;
  border: 0;
  &:hover {
    .highlighted-informations {
      transform: translateY(0);
    }
  }

  .discount {
    display: none;
  }
}

.card-img-top {
  width: 17.25rem;
  height: 17.25rem;
  overflow: hidden;
  .product-thumbnail {
    img {
      width: 100%;
    }
  }
}

.highlighted-informations {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $pm-highlight-bg-color;
  transform: translateY(100%);
  transition: transform 0.3s;
  .card-product:hover & {
    transform: translateY($pm-highlight-translateY);
  }
}
.quick-view {
  cursor: pointer;
}

.product-flags {
  position: absolute;
  top: 1.0194rem;
  margin-bottom: 0;
  z-index: 1;
  left: 1.0194rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.page-content--product {
  .product-flags {
    left: 0;
  }
}
.product-flag {
  top: 0;
  padding: 0.375rem 0.75rem;
  // color: $white;
  // background: theme-color("primary");
  background: $white;
  color: $black;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 5px;

  @include media-breakpoint-down(md) {
    font-size: 11px;
    line-height: 16px;
  }

  &.discount-product {
    background: $black;
    color: $white;
  }
  &.new {
    background: $black;
    color: $white;
  }
}
.color {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  background-size: contain;
}

.product-title {
  color: $black;
  font-size: 1.25rem;
  line-height: 1.8125rem;

  @include media-breakpoint-down(md) {
    font-size: 18px;
    line-height: 26px;
    min-height: 52px;
  }

  font-family: $font-family-sans-serif;
  text-decoration: none;
  text-align: left;
  font-weight: $pm-name-font-weight;
  margin-bottom: 10px;
  min-height: 58px;
  & a {
    color: $black;
    &:hover {
      color: $black;
      border-bottom: $pm-name-hover-border-width solid
        $pm-name-hover-border-color;
    }
  }
  .card-product:hover & {
    color: $black;
    & a {
      color: $black;
    }
  }
}

/* PRODUCT PAGE */

.products-imagescover {
  box-shadow: $card-box-shadow;
}
.product-img:not(:first-child) .rc {
  display: none;
}
.slick-initialized .product-img .rc {
  display: block;
}

.product-thumbs {
  width: $product-thumb-wrap-width;
  height: $product-thumb-wrap-height;
  margin: 0;
  &[data-count="2"] {
    width: $product-thumb-wrap-width-2;
  }
  .slick-slide > div {
    cursor: pointer;
  }
  &:not(.slick-initialized) > div:not(:first-child) {
    display: none;
  }

  .slick-arrow {
    box-shadow: none;
    &.slick-prev {
      display: none;
      visibility: hidden;
    }
    &.slick-next {
      right: -20px;
      .material-icons {
        background-image: url(../img/thumbs-right.svg);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50%;
        color: transparent;
        width: 40px;
        height: 40px;
        display: block;
        align-items: center;
        border: 1px solid $black0;
        border-radius: 100%;
        transition: box-shadow 0.5s;
        &:hover {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
.product-thumb {
  margin: $product-thumb-margin $product-thumb-margin 0 $product-thumb-margin;
  width: $product-thumb-width;
  height: $product-thumb-height;
  border: 0px solid transparent;
  &.slick-current {
    border: 0px solid theme-color("primary");
  }
}
.btn-zoom {
  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  & .material-icons {
    font-size: 3 * $font-size-base;
  }
  i.zoom-in {
    background: url("../img/zoom-in.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    width: 20px;
    height: 20px;
  }
}
.images-container {
  #_desktop_pwfavorites {
    position: absolute;
    top: 20px;
    z-index: 1;
    right: 20px;
    .pw-fav-link {
      margin: 0;
      a {
        background: $white url("../img/icon-favoris.svg");
        background-size: 16px 20px;
        background-repeat: no-repeat;
        background-position: center;
        @extend .right_icon;
        i {
          &.material-icons {
            display: none;
          }
        }
      }
    }
  }
  &:hover {
    .btn-zoom {
      display: block;
      background: $black;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      right: 25px;
      bottom: 20px;
    }
  }
}
.label-color {
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item .color {
  width: 2rem;
  height: 2rem;
  border: 2px solid transparent;
}

.input-color:checked + span,
.input-color:hover + span,
.input-radio:checked + span,
.input-radio:hover + span {
  border-color: $colorbox-border-color;
}

//product prices
.product__product-price {
  &.has-discount {
    .current-price {
      display: inline-block;
    }
  }
}
.price {
  color: $product-price-current-color;
  font-size: $product-price-current-font-size;
  font-weight: $product-price-current-font-weight;
  font-family: $product-price-current-font-family;
  &.current-price-discount {
    color: $product-price-discount-color;
    font-size: $product-price-discount-font-size;
    font-weight: $product-price-discount-font-weight;
    font-family: $product-price-discount-font-family;
  }
}
.regular-price {
  color: $product-price-regular-color;
  font-size: $product-price-regular-font-size;
  font-weight: $product-price-regular-font-weight;
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
.discount {
  text-transform: uppercase;
  color: #fff;
  background-color: $product-price-discount-color;
  font-weight: 600;
  display: inline-block;
  padding: 0.25 * $spacer;
}

//product add to cart
.btn-add-to-cart {
  //@extend .d-flex;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
}

//product tabs
.product-tabs {
  .nav-tabs {
    font-size: $font-size-base * 0.9;
  }
}
.card--reassurance {
  box-shadow: $card-box-shadow;
}

// modal add to cart
.cart-content {
  padding: $spacer;
  background: theme-color("light");
}

//product pack
.pack-product-container {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask {
  width: 55px;
}
.thumb-mask {
  padding: $spacer 0;
}
.pack-product__img {
  border: 1px solid $border-color;
}
.pack-product-name {
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity {
  border-left: 1px solid $border-color;
}
.pack-product-price {
  padding-right: $spacer/2;
}

.pack-product-quantity {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image {
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name {
  color: $black;
  font-weight: $font-weight-bold;
}
.product-total {
  font-weight: $font-weight-bold;
}
.cart-content {
  > p:not(:last-child) {
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image {
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right {
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, 0.15);
    }
    .modal-body {
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size * $btn-line-height) +
        $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image {
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading {
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection {
  justify-content: space-between;
  align-items: center;
}
.pack-miniature-item {
  margin-bottom: $spacer / 2;
}
.ratio1_1 {
  display: block;
}
.pack-product-name {
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature {
  margin-bottom: $spacer;
  // max-width: 316px;
  width: 316px;
  min-height: 494px;
  padding: 19px;
  background: $white;
  border: 1px solid $light-grey;
  .card-body {
    padding: 10px 0 0 0;
  }
  img {
    overflow: hidden;
  }
  .product-img-slider {
    &.slick-initialized {
      &.slick-slider {
        .slick-prev {
          left: 0;
          opacity: 0;
          &:focus{
            box-shadow: none;
          }
          .icons-left {
            border: 1px solid $white;
            background-image: url(../img/left-miniature.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 12px;
            width: 40px;
            height: 40px;
            display: block;
            border-radius: 100%;
            transition: box-shadow 0.5s;
            &:hover {
              background: $light-grey url(../img/left-miniature.svg);
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 12px;
            }
          }
        }
        .slick-next {
          right: 0;
          opacity: 0;
          &:focus{
            box-shadow: none;
          }
          .icons-right {
            border: 1px solid $white;
            background-image: url(../img/right-miniature.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 12px;
            width: 40px;
            height: 40px;
            display: block;
            border-radius: 100%;
            transition: box-shadow 0.5s;
            &:hover {
              background: $light-grey url(../img/right-miniature.svg);
              background-repeat: no-repeat;
              background-position: 50%;
              background-size: 12px;
            }
          }
        }
      }
    }
    &:hover {
      &.slick-initialized {
        &.slick-slider {
          > .slick-prev {
            opacity: 1;
          }
          > .slick-next {
            opacity: 1;
          }
        }
      }
    }
  }

  .pw-fav-toggle {
    position: absolute;
    top: 16.3104px;
    z-index: 1;
    right: 16.3104px;
    left: unset;
    float: right;
    width: 40px;
    height: 40px;

    .pw-fav-add,
    .pw-fav-remove {
      .fav_icon {
        width: 40px;
        height: 40px;
        background: $white;
        border-radius: 100%;
        transition: box-shadow 0.5s;
        overflow: hidden;
        position: relative;
        padding: 10px 12px;
        &:hover {
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        }
        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }
  }
}
.product__card-img {
  position: relative;
}
.product__card-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .features {
    height: 100px;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
  }
}
.highlighted-informations {
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label {
  margin-bottom: 0;
  margin-top: $spacer;
}
.product-add-to-cart {
  .product-quantity {
    margin-top: -($spacer);
  }
  .qty {
    margin: 0 $spacer;
    margin-top: $spacer;
  }
  .add {
    margin-top: $spacer;
  }
}
.product-customization {
  margin-bottom: $spacer * 1.5;
}
.product-discounts {
  margin-bottom: $spacer * 1.5;
}
.product__quantity {
  margin-bottom: 0;
}
.sort-by-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  padding-bottom: 1.25rem;
  @include media-breakpoint-down(md) {
    flex-direction: column;
    border-bottom: none;
    margin-bottom: 1.25rem;
  }
  .form-inline {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .form-group {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      font-size: 1.25rem;
      line-height: 1.8125rem;
      flex-flow: column;
      align-items: unset;
      .label-sort-order {
        margin-bottom: 20px;
      }
      #select-sort-order {
        align-items: flex-start;
        box-shadow: none;
        height: 2.5rem;
        padding: 0.625rem;
        color: $black;
        border: 1px solid $light-grey;
        background: $white url("../img/filtre-down.svg") no-repeat right 0.75rem
          center/12px 6px;
      }
    }
  }
  #search_filter_toggler {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $black;
    background-color: $black;
    min-height: 40px;
    box-shadow: none;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      background-image: url("../img/filter-icon.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 10px;
    }
  }
}
.total__item-show {
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links {
  display: flex;
  justify-content: center;
}
.product__download {
  text-transform: uppercase;
}
.card--address {
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address {
  margin-top: $spacer;
}
.invoice-address {
  height: 100%;
  text-align: center;
  justify-content: center;
}
.nav-tabs {
  justify-content: center;
}
#Layer_1 {
  width: 19px;
}
