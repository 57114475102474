header {
  &#header {
    .header-nav {
      .header__container {
        background: $ultra-light-grey;
        padding: 0 60px;
        @include media-breakpoint-down(sm) {
          padding: 0 22px;
        }
        .top_header_nav {
          min-height: 50px;
          .top_info {
            font-size: 14px;
            line-height: 20px;
            @include media-breakpoint-down(sm) {
              font-size: 11px;
              line-height: 16px;
            }
            color: $black;
            display: flex;
            align-items: center;
            &::before {
              content: "";
              background-image: url("../img/phone.svg");
              background-size: 20px;
              background-repeat: no-repeat;
              @include media-breakpoint-down(sm) {
                width: 30px;
              }
              width: 20px;
              height: 20px;
              display: inline-block;
              margin-right: 10px;
              position: relative;
            }
            a {
              color: $black;
            }
            p {
              margin-bottom: 0;
            }
          }
          .header-nav__right {
            .language-selector {
              button {
                font-size: 11px;
                text-transform: lowercase;
                color: $black;
                box-shadow: none;
                padding: 0;
                line-height: 16px;
                border: 0;
                display: flex;
                align-items: center;
                .lang-flag {
                  margin-right: 5px;
                }
                &::after {
                  content: "";
                  background-image: url("../img/down-lang.svg");
                  background-size: 10px 7px;
                  background-repeat: no-repeat;
                  width: 10px;
                  height: 7px;
                  border: 0;
                  margin-left: 8px;
                }
              }

              .dropdown-menu {
                &.show {
                  background: $light-grey;
                  border: 0;
                  padding: 0;
                  min-width: 100px;
                  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                  .dropdown-item {
                    padding: 0;
                    line-height: 16px;
                    padding: 10px;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    img {
                      margin-right: 5px;
                    }
                    &.active {
                      background-color: transparent;
                    }
                    &:hover {
                      color: $white;
                    }
                    &:last-child {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .header-top {
      padding: 1.25rem 3.75rem;
      @include media-breakpoint-down(sm) {
        padding: 1.25rem 1.375rem;
      }
      .header__search {
        padding: 0;
        max-width: 270px;
        @include media-breakpoint-down(sm) {
          max-width: 100%;
          margin-top: 20px;
        }
        .search-widget {
          width: 100%;
          margin: 0;
          max-width: 220px;
          .search-widget__input-right {
            min-height: 2.5rem;
            border: 0.0625rem solid $light-grey;
            box-shadow: none;
            padding-left: 20px;
            transition: box-shadow 0.5s;
            &:hover {
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            }
          }
          .search-widget__btn {
            padding: 10px 20px;
            margin: 0;
            border: none;
            .loop {
              background-image: url("../img/loop.svg");
              background-size: 20px;
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }
      }
      .header__logo {
        img {
          &.logo {
            display: block;
            margin: 0 auto;
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
      }
      .header__right {
        max-width: 270px;
        @include media-breakpoint-down(sm) {
          max-width: 40px;
        }
        width: 100%;
        // &.is_logged{
        //     @include media-breakpoint-up(sm) {
        //         min-width: 225px;
        //     }
        // }
        #_desktop_pwfavorites {
          .pw-fav-link {
            margin: 0 10px 0 0;
            a {
              background-image: url("../img/icon-favoris.svg");
              background-size: 16px 20px;
              background-repeat: no-repeat;
              background-position: center;
              @extend .right_icon;
              i {
                &.material-icons {
                  display: none;
                }
              }
            }
          }
        }
        .user-info {
          @include media-breakpoint-down(sm) {
            display: none;
          }
          .myaccount {
            background-image: url("../img/my-account.svg");
            background-size: 16px 20px;
            background-repeat: no-repeat;
            background-position: center;
            @extend .right_icon;
          }
        }
        .shopping-cart {
          position: relative;

          .header_panier {
            background-image: url("../img/toppanier.svg");
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: center;
            @extend .right_icon;
          }
          .blockcart__count {
            background: $black;
            color: $white;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            position: absolute;
            right: -5px;
            bottom: -10px;
          }
        }
      }
      #menu-icon {
        padding: 0;
        .icon_menu {
          background-image: url("../img/menu.svg");
          background-size: 20px 14px;
          background-repeat: no-repeat;
          background-position: center;
          @extend .right_icon;
        }
      }
    }
    #_desktop_top_menu {
      padding: 0px 40px 20px 40px;
    }
  }

  /** menu scss**/
  #top-menu {
    .menu-sub__list[data-depth="2"] {
      display: none;
      box-shadow: 2px 1px 11px 2px rgba(240, 74, 74, 0.1);
      border: none;
      margin-left: 0px;
      width: 100%;
      min-width: initial;
      z-index: 18;
      li {
        a {
          background-size: 0% 100%;
          font-size: 13px;
        }
      }
    }
    .menu-sub__list[data-depth="2"].current-submenu {
      display: block;
      position: absolute;
      left: 100%;
      background-color: white;
      margin-top: -45px;
      padding-left: 20px;
    }
    .menu-sub__list[data-depth="2"]#current-col {
      width: 960px !important;
      columns: 5 auto;
      column-gap: 40px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .hover-add {
      margin: 0;
    }
    .menu__item-header{
      width: 100%;
    }
  }
}
