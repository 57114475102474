body {
    &#cms {
        .page-wrapper--cms {
            border: none;
            .page-header--cms {
                padding: 12px 20px;
                margin-bottom: 0;
                background-color: transparent;
                border: 0;
                text-align: center;
                h1 {
                    font-family: $font-family-sans-serif;
                }
            }
            .page-content--cms {
                padding: 0;
                .cms_slick_draggable {
                    .slick-list {
                        &.draggable {
                            min-height: 550px;
                            @include media-breakpoint-down(md) {
                                min-height: 207px;
                            }
                        }
                    }
                    .slick-prev {
                        @include media-breakpoint-down(md) {
                            top: calc(100% + 20px);
                            left: calc(50% - 70px);
                        }
                        &::before {
                            background: $black url(../img/left-miniature.svg);
                            @include media-breakpoint-down(md) {
                                background: $white url(../img/left-miniature-black.svg);
                                background-size: 12px;
                                background-repeat: no-repeat;
                                background-position: 50%;
                                border: 1px solid $black;
                            }
                            background-size: 12px;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            color: transparent;
                            opacity: 1;
                            font-size: 40px;
                            padding: 0 13px;
                            border-radius: 100%;
                            left: 20px;
                            top: 50%;
                            position: absolute;
                        }
                    }
                    .slick-next {
                        @include media-breakpoint-down(md) {
                            top: calc(100% + 20px);
                            right: calc(50% - 70px);
                        }
                        &::before {
                            background: $black url("../img/right-miniature.svg");
                            @include media-breakpoint-down(md) {
                                background: $white url(../img/right-miniature-black.svg);
                                background-size: 12px;
                                background-repeat: no-repeat;
                                background-position: 50%;
                                border: 1px solid $black;
                            }
                            background-size: 12px;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            color: transparent;
                            opacity: 1;
                            font-size: 40px;
                            padding: 0 13px;
                            border-radius: 100%;
                            right: 20px;
                            top: 50%;
                            position: absolute;
                        }
                    }
                }
                .header_realisation{
                    @include media-breakpoint-down(md) {
                        margin: 0 -15px;
                    }
                }
                .cms_realisations {
                    .ce-gallery-item {
                        position: relative;
                        &:hover {
                            figcaption {
                                @include media-breakpoint-up(md) {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                        figcaption {
                            @include media-breakpoint-up(md) {
                                visibility: hidden;
                                opacity: 0;
                                transition: visibility 0s, opacity 0.2s linear;
                            }
                            position: absolute;
                            background: $white;
                            bottom: 10px;
                            min-height: 100px;
                            padding: 20px;
                            margin: 20px;
                            max-width: 100%;
                            width: calc(100% - 60px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $font-family-sans-serif;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
