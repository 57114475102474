body{
    &#contact{
        .row_contact{
            margin-bottom: 3.75rem;
            h1 {
                font-family: $font-family-sans-serif;
            }
            input[name="submitMessage"]{
                float: right;
            }
        }
        
    }
}