body {
  background-color: $white;
  color: $black;
}
a {
  text-decoration: none;
}
sup {
  top: -0.3em;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.l-wrapper,
.l-wrapper--boxed,
main > .notifications-container {
  @include media-breakpoint-up(xl) {
    max-width: 1440px;
  }
  @include media-breakpoint-down(xl) {
    max-width: 100%;
  }
}

.l-wrapper {
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed {
    @extend .container;
  }
}
.page-wrapper,
.card-block {
  @extend .card;
}
.page-header {
  @extend .card-header;
  h1 {
    margin-bottom: 0;
  }
}
.page-content,
.card-block {
  @extend .card-body;
}
.card-block {
  box-shadow: $card-box-shadow;
}
.page-footer {
  @extend .card-footer;
  &:empty {
    display: none;
  }
}

.left-column > div,
.right-column > div {
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer {
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,
.h1 {
  text-transform: $h1-text-transform;
}
.quick-view {
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password {
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin {
  box-shadow: $bs-touchspin-boxshadow;
  border: $input-border-width solid $input-border-color;
  > input {
    max-width: 60px;
    border: none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    color: $input-color;
    background-color: $white;
  }
  .input-group-btn {
    display: flex;
    &:first-child {
      margin-right: -$input-border-width;
    }
    &:last-child {
      margin-left: -$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0, 0, 0, 0.05);
  transition: background 150ms;
  &:after {
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after {
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1 {
  padding-top: 50%;
}
.ratio1_2 {
  padding-top: 200%;
}
.ratio4_3 {
  padding-top: 75%;
}
.ratio16_9 {
  padding-top: percentage(9/16);
}
.ratio1_1 {
  padding-top: 100%;
}
.ratio3_2 {
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse {
  transition: transform 0.4s ease;
}
.icon-collapse[aria-expanded="true"] {
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body {
  padding-bottom: 0;
}
.step-edit[aria-expanded="true"] {
  display: none;
}
.step-title {
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
  display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header {
  background-color: $white;
}

.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;
  }
  .card-body .card-header {
    text-align: center;
  }
  .selected .card {
    border-color: $success;
    border-width: 2px;
  }
}
//Personnal info
.nav-tabs-info {
  font-size: $font-size-base * 1.1;
  .active {
    font-weight: bold;
  }
}

//Addresses

.address-selector {
  .card {
    transition: border 0.1s linear;
  }
  .card-header,
  .card-body {
    cursor: pointer;
  }
}
.card-body_add-address {
  padding: $card-spacer-y $card-spacer-x;
  .material-icons {
    opacity: 0.8;
  }
}
// SHipping
.delivery-option {
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}
.carrier-extra-content:not(:empty) {
  margin-bottom: $spacer * 2;
  &:empty {
    display: none;
  }
}
.carrier-name {
  font-weight: $font-weight-bold;
}
.carrier-price {
  font-weight: bold;
  font-size: $font-size-lg;
  color: $primary;
}
//order list final recap
.order-line:not(:last-child) {
  margin-bottom: $spacer/2;
}
.order-confirmation-table {
  padding: $spacer;
  border: 3px solid $card-border-color;
}

//forms
custom-file-label.selected:after {
  content: "" !important;
}
.label {
  @extend label;
}
//nav tabs
.nav-tabs {
  .nav-link {
    color: $gray-600;
    font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content {
  padding-top: $spacer;
}
//products-section-title
.products-section-title {
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color: $section-title-color;
  font-size: $section-title-size;
  font-weight: $section-title-font-weight;
}

//home content spacing
.page-home > * {
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer {
    flex-direction: column;
    flex-direction: column-reverse;
    & > .btn {
      margin: 0;
      width: 100%;
    }
    & .btn:not(:last-child) {
      margin-top: $spacer/2;
    }
  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container {
  @extend .l-wrapper;
}
.notifications-container {
  ul {
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty {
  display: none;
}
.form-group {
  label,
  .label {
    small,
    .small {
      font-size: 0.75 * $font-size-base;
    }
  }
}
@include media-breakpoint-desktop {
  .page-content,
  .checkout-form,
  .page-wrapper--order-confirmation {
    form {
      width: 100%;
      max-width: 650px;
      margin: 0 auto;
      padding: 0 20px;
    }
  }
  .page-wrapper--order-detail {
    form {
      margin: unset;
    }
  }
}
.form-footer {
  margin-top: $spacer;
}

// badge order history
.badge.bright {
  color: $white;
}

.form-control {
  min-height: 2.5rem;
  border: 0.0625rem solid $dark-grey-100;
  &::placeholder {
    color: $light-grey;
  }
  &:focus {
    border-color: none;
    box-shadow: none;
    border: 0.0625rem solid $dark-grey-100;
  }
}

.right_icon {
  width: 40px;
  height: 40px;
  display: block;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 100%;
  transition: box-shadow 0.5s;
  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }
}
#content-wrapper {
  @include media-breakpoint-up(md) {
    padding-right: 0;
    padding-left: 0;
  }
}
a {
  &.cta {
    font-size: 14px;
    line-height: 20px;
    color: $black;
    display: block;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    border: 1px solid;
    padding: 10px 39px 10px 20px;
    width: fit-content;
    transition: box-shadow 0.5s;
    &:hover {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      background: $ultra-light-grey;
    }
    &:after {
      content: "";
      background-image: url("../img/icon-right.svg");
      background-size: 6px 12px;
      background-repeat: no-repeat;
      width: 6px;
      height: 12px;
      display: inline-block;
      position: relative;
      top: 2px;
      left: 12px;
    }
  }
}

.breadcrumb {
  justify-content: center;
  padding: 0;
  margin-bottom: 20px;
  background: transparent;
  border-top: 1px solid $light-grey;
  width: fit-content;
  margin: 0 auto 20px auto;
  padding-top: 5px;
  li {
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    &.breadcrumb-item {
      &.active {
        font-weight: 700;
        color: $dark-grey;
      }
      a {
        color: $dark-grey;
      }
      + {
        .breadcrumb-item {
          &::before {
            display: inline-block;
            padding-right: 0.5rem;
            content: "";
            background-image: url("../img/breadcrumb.svg");
            background-size: 3.6px 7.2px;
            background-repeat: no-repeat;
            width: 3.6px;
            height: 7.2px;
            margin-left: 14px;
            margin-right: 14px;
          }
        }
      }
    }
  }
}

.custom-radio {
  .custom-control-input {
    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            color: $white;
            border-color: $black;
            background-color: $white;
            box-shadow: none;
            width: 20px;
            height: 20px;
          }
          &::after {
            background: $black;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            top: 6px;
            left: -21px;
          }
        }
      }
    }
  }
}
.custom-checkbox {
  .custom-control-input {
    &:focus {
      &:not(:checked) {
        ~ {
          .custom-control-label {
            &::before {
              border-color: $light-grey;
            }
          }
        }
      }
      ~ {
        .custom-control-label {
          &::before {
            box-shadow: none;
          }
        }
      }
    }

    &:checked {
      ~ {
        .custom-control-label {
          &::before {
            color: $white;
            border-color: $black;
            background-color: $white;
            box-shadow: none;
            width: 20px;
            height: 20px;
          }
          &::after {
            background: $black;
            width: 12px;
            height: 12px;
            border-radius: 0;
            top: 7px;
            left: -20px;
          }
        }
      }
    }
  }
}

.custom-control-label {
  line-height: 25px !important;
  &::before {
    width: 20px;
    height: 20px;
    border: 1px solid $light-grey;
    box-shadow: none;
  }
  &:hover {
    &::before {
      border: 1px solid $dark-grey-100;
      box-sizing: border-box;
      box-shadow: 0px 0px 6px rgba(31, 31, 31, 0.3);
    }
  }
}

.show_hide_pwd {
  position: absolute;
  right: 20px;
  top: 0.7813rem;
  display: flex;
  button {
    background: url("../img/show-pwd.svg");
    background-repeat: no-repeat;
    background-size: 20px 15px;
    width: 20px;
    height: 15px;
    box-shadow: none;
    border: 0;
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    &:not(:disabled) {
      &:not(.disabled) {
        &:active {
          box-shadow: none;
          background-color: transparent;
          border-color: transparent;
          &:focus {
            box-shadow: none;
            background-color: transparent;
            border-color: transparent;
          }
        }
        &:focus {
          box-shadow: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
  }
}

.custom-select {
  min-height: 2.5rem;
  border: 0.0625rem solid $dark-grey-100;
  &:focus {
    border-color: $dark-grey-100;
    box-shadow: none;
  }
}

.btn-primary {
  background-color: $black;
  border-color: $black;
  box-shadow: none;
  &:disabled{
    background-color: $black;
    border-color: $black;
  }
  &:hover {
    background-color: $black;
    border-color: $black;
  }
  &:active {
    background-color: $black;
    border-color: $black;
  }
  &:focus {
    background-color: $black;
    border-color: $black;
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $black;
    border-color: $black;
    box-shadow: none;
  }
}
.btn-outline-primary {
  // background-color: $black;
  border-color: $black;
  color: $black;

  &:hover {
    background-color: $black;
    border-color: $black;
    color: $white;
  }
}

a {
  color: $black;
  &:hover {
    color: $dark-grey;
  }
}

.icon_devis {
  background: url("../img/icon_devis.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 16px;
  width: 20px;
  height: 16px;
  display: inline-block;
  margin-right: 10px;
}

.go__up {
  a {
    background: none;
    color: $black;
    border: 1px solid $black;
    display: flex;
    min-height: 40px;
    align-items: center;
    justify-content: center;
    &:active {
      background: none;
      color: $black;
    }
    &:focus {
      background: none;
      color: $black;
    }
  }
}


.pagination__controls {
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  ul {
      display: inline-flex;
      margin: 0;
      li {
          border: 0.0625rem solid $light-grey;
          line-height: 20px;
          margin: 0 5px 20px 5px;
          cursor: pointer;
          min-height: 2.5rem;
          min-width: 1.875rem;
          transition: box-shadow 0.5s;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          line-height: 20px;
          color: $dark-grey-100;
          &.next {
              border: 0;
              &:hover {
                  box-shadow: none;
              }
          }
          &.prev {
              border: 0;
              &:hover {
                  box-shadow: none;
              }
          }
      }
  }
}

.show_all {
  border: 1px solid $dark-grey-100;
  line-height: 20px;
  text-align: center;
  color: $dark-grey-100;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: box-shadow 0.5s;
  padding: 0 20px;
  margin: auto;
  width: max-content;
  &:hover {
      box-shadow: $box-shadow;
      color: $white;
      background: $black;
      border: 1px solid $black;
  }
}

.table_declinaisons .btn-primary {
  border-radius: 50%;
  height: 45px;
}

.table_declinaisons .bootstrap-touchspin .btn { 
  padding: 0px;
}