#identity,
#my-account,
#address,
#history,
#order-slip,
#module-psgdpr-gdpr {
    #main {
        margin-bottom: 60px;
        h1 {
            text-align: center;
            font-family: $font-family-sans-serif;
        }
        .form-footer {
            text-align: center;
            button {
                min-height: 40px;
                background: $black;
                border: 0;
                color: $white;
                box-shadow: none;
                padding: 0 20px;
            }
        }
        .page-footer {
            background: none;
            padding: 0;
            min-height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                color: $black;
                &:not(:last-child) {
                    margin-right: 30px;
                }
            }
        }
        .page-footer--my-account {
            a {
                color: $black;
            }
        }
    }
}
