body#product {
    .content__product {
        padding: 40px 0px;
        @include media-breakpoint-down(md) {
            padding: 0;
        }
        .col-lg-6 {
            padding-right: 20px;
            padding-left: 20px;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            &:first-child {
                @include media-breakpoint-down(md) {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
            .page-content--product {
                @include media-breakpoint-down(md) {
                    position: relative;
                }
                .product-flags {
                    left: 40px;
                    top: 20px;
                }
                .product-thumbs {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
                .products-imagescover {
                    overflow: hidden;
                    position: relative;
                    height: 540px;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: 414px;
                    }
                    .product-img {
                        width: 100%;
                        height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                    }
                }
            }
            h1 {
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
                color: $black;
                text-transform: initial;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    font-size: 25px;
                    line-height: 33px;
                    text-align: center;
                }
            }
            .product-reference {
                font-size: 14px;
                line-height: 20px;
                color: $dark-grey-100;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    margin-top: 12px;
                }
                .label {
                    margin: 0;
                }
            }
            .product-information {
                .product_description_short {
                    line-height: 20px;
                    margin-bottom: 20px;
                }
                .product-features {
                    margin-bottom: 20px;
                    .feature {
                        line-height: 20px;
                        margin-bottom: 0;
                    }
                }
                .product-variants {
                    .product-variants-item {
                        select {
                            min-width: 300px;
                            box-shadow: none;
                        }
                    }
                }
                .product-prices {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 22px;
                    margin-bottom: 20px;
                    .current-price-discount {
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 22px;
                        color: $black;
                    }
                    .discount {
                        &.discount-percentage {
                            font-size: 14px;
                            line-height: 20px;
                            text-transform: initial;
                            font-weight: 400;
                            background: $black;
                            padding: 6px 12px;
                            margin-right: 20px;
                        }
                    }
                }
                .product-add-to-cart {
                    margin-bottom: 20px;
                    #product-availability {
                        padding-top: 20px;
                        display: block;
                        @include media-breakpoint-down(md) {
                            padding: 0;
                        }
                    }
                    .product-quantity {
                        margin-top: 0;
                        .add {
                            margin-top: 0;
                            @include media-breakpoint-down(md) {
                                margin: 0 auto;
                                width: 100%;
                            }
                            .btn-add-to-cart {
                                &.js-add-to-cart {
                                    background: $black;
                                    border-radius: 50px;
                                    border: 0;
                                    min-height: 60px;
                                    font-weight: 700;
                                    font-size: 14px;
                                    line-height: 20px;
                                    text-align: center;
                                    color: $white;
                                    box-shadow: none;
                                    display: flex;
                                    align-items: center;
                                    @include media-breakpoint-down(md) {
                                        justify-content: center;
                                    }
                                    padding: 0 20px;
                                    .btn-add-to-cart__icon {
                                        background: url("../img/add-to-cart.svg");
                                        background-repeat: no-repeat;
                                        background-size: 20px 20px;
                                        width: 20px;
                                        height: 20px;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }

                        .qty {
                            margin: 0 20px 0 0;
                            @include media-breakpoint-down(md) {
                                margin: 0 auto 20px auto;
                            }
                            .bootstrap-touchspin {
                                box-shadow: none;
                                min-height: 60px;
                                align-items: center;
                                display: flex;
                                justify-content: center;
                                border: 1px solid $dark-grey-100;
                                box-sizing: border-box;
                                border-radius: 100px;
                                min-width: 170px;
                                #quantity_wanted {
                                    border-left: 1px solid $light-grey;
                                    border-right: 1px solid $light-grey;
                                    min-height: 58px;
                                }
                                .input-group-btn {
                                    &:first-child {
                                        margin-right: 20px;
                                    }
                                    &:last-child {
                                        margin-left: 20px;
                                    }
                                    .btn {
                                        border: 0px;
                                        padding: 0;
                                        color: transparent;
                                        &:not(:disabled) {
                                            &:not(.disabled).active {
                                                box-shadow: none;
                                            }
                                            &:not(.disabled) {
                                                &:active {
                                                    box-shadow: none;
                                                }
                                            }
                                        }
                                        &.bootstrap-touchspin-up {
                                            background: url("../img/touchspin-up.svg");
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            background-size: 16px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                        &.bootstrap-touchspin-down {
                                            background: url("../img/touchspin-down.svg");
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            background-size: 16px;
                                            width: 16px;
                                            height: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                a {
                    &.cta {
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            justify-content: center;
                        }
                        &.guest {
                            background: $black;
                            color: $white;
                            .icon_devis {
                                background: url(../img/icon_devis_white.svg);
                                background-repeat: no-repeat;
                                background-position: 50%;
                                background-size: 20px 16px;
                            }
                        }
                    }
                }
            }
            /** SELECT OPTION */
            .product-variants {
                #dropdown-product-variants-item {
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        justify-content: center;
                    }
                    width: max-content;
                    min-height: 40px;
                    text-align: left;
                    padding: 0 20px;
                    font-size: 14px;
                    color: $black;
                    background: transparent;
                    border: 1px solid $black;
                    box-sizing: border-box;
                    transition: box-shadow 0.5s;
                    position: relative;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    &:hover {
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                    }
                    &:focus {
                        box-shadow: none;
                    }
                    &:active {
                        box-shadow: none;
                    }
                    &::after {
                        background: url("../img/down-dec.svg");
                        background-repeat: no-repeat;
                        background-size: 12px 6px;
                        width: 12px;
                        height: 6px;
                        border: 0;
                        right: 20px;
                        position: absolute;
                    }
                    .selected_item {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding-right: 20px;
                    }
                }
                .dropdown-menu {
                    &.show {
                        width: max-content;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                        }
                        padding: 5px 10px;
                        border: 1px solid $light-grey;
                    }
                    .item_attribute {
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        min-height: 40px;
                        padding: 5px 30px 5px 0;
                        cursor: pointer;
                        margin-bottom: 0;
                        input[type="radio"] {
                            &.dropdown-item {
                                display: none;
                            }
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid $light-grey;
                        }
                        .image_produit {
                            overflow: hidden;
                            position: relative;
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                                -o-object-fit: cover;
                                object-fit: cover;
                            }
                        }
                        &::after {
                            content: "";
                            background: url("../img/item-right.svg");
                            background-repeat: no-repeat;
                            background-size: 7px 10px;
                            width: 7px;
                            height: 10px;
                            border: 0;
                            right: 15px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
    .product___description {
        min-height: auto;
        margin-top: 60px;
        margin-bottom: 60px;
        .product__info {
            margin-top: 164px;
            @include media-breakpoint-down(md) {
                margin: 136px 5px;
            }
            background: $white;
            padding: 40px;
            h2 {
                font-weight: bold;
                font-size: 25px;
                line-height: 33px;
                margin-bottom: 20px;
            }
            .product-description {
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;
                p {
                    margin: 0;
                }
            }
            a.cta {
                line-height: 18px;
                display: flex;
                align-items: center;
            }
        }
    }
    .product-accessories {
        .products-section-title {
            font-weight: bold;
            font-size: 25px;
            line-height: 33px;
            text-transform: initial;
            margin-bottom: 20px;
            @include media-breakpoint-down(md) {
                padding: 0 20px;
            }
        }
        .products-section-descr {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 40px;
            text-align: center;
            @include media-breakpoint-down(md) {
                padding: 0 20px;
            }
        }
        /*******/
        .product__accessories {
            max-width: 1320px;
            padding-bottom: 45px;
            margin: 0 auto;
            @include media-breakpoint-down(md) {
                padding: 0 0 0 20px;
            }
            .slick-list.draggable {
                margin: 0px 114px;
                @include media-breakpoint-down(md) {
                    margin: 0;
                    padding: 0 70px 0 0;
                }
            }
            .product-miniature {
                margin: 0 36px;
                @include media-breakpoint-down(md) {
                    margin: 0 5px;
                }
            }
            .btn {
                &.slick-next {
                    right: 0;
                    padding: 0;
                    @include media-breakpoint-down(md) {
                        display: none !important;
                    }
                    .icons-right {
                        background-image: url("../img/right.svg");
                        background-size: 12px;
                        background-repeat: no-repeat;
                        background-position: center;
                        @extend .icon;
                    }
                }
                &.slick-prev {
                    left: 0;
                    padding: 0;
                    @include media-breakpoint-down(md) {
                        display: none !important;
                    }
                    .icons-left {
                        background-image: url("../img/left.svg");
                        background-size: 12px;
                        background-repeat: no-repeat;
                        background-position: center;
                        @extend .icon;
                    }
                }
                &:focus {
                    box-shadow: none;
                }
            }

            .icon {
                width: 40px;
                height: 40px;
                display: block;
                align-items: center;
                border: 1px solid $black0;
                border-radius: 100%;
                transition: box-shadow 0.5s;
                &:hover {
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
        /******/
    }
}
