body#authentication {
    .row_authentication {
        background: url(../img/bg-cnx.svg);
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 3.75rem;
        padding-top: 3.75rem;
        @include media-breakpoint-down(md) {
            padding-top: 2.5rem;
        }
        #content-wrapper {
            padding-right: 22px;
            padding-left: 22px;
            .page-wrapper--authentication {
                background: none;
                border: 0;
                padding-bottom: 3.75rem;
                @include media-breakpoint-down(md) {
                    padding-bottom: 2.5rem;
                }
                margin: 0 auto;
                max-width: 650px;
                .page-header--authentication {
                    padding: 0;
                    background: none;
                    border: 0;
                    margin-bottom: 1.25rem;
                    h1 {
                        font-weight: 700;
                        font-size: 1.875rem;
                        line-height: 2.5rem;
                        text-align: center;
                        text-transform: unset;
                        margin-bottom: 20px;
                        @include media-breakpoint-down(md) {
                            font-size: 25px;
                            line-height: 33px;
                        }
                    }
                    .seo_auth {
                        line-height: 1.25rem;
                        text-align: center;
                        p {
                            margin: 0;
                        }
                    }
                }
                .page-content--authentication {
                    background: $white;
                    padding: 2.5rem 2.5rem 0rem 2.5rem;
                    border-width: 1px 1px 0px 1px;
                    border-color: $light-grey;
                    border-style: solid;
                    .form-fields {
                        .form-group {
                            margin-bottom: 20px;
                            label {
                                line-height: 20px;
                                margin-bottom: 10px;
                                position: relative;
                                &.required {
                                    &::after {
                                        content: "*";
                                        color: $wrong;
                                        position: absolute;
                                    }
                                }
                            }
                            .input_pwd {
                                position: relative;
                            }
                            &.form-group_forgot-password {
                                margin-top: 0;
                                .forgot-password {
                                    a {
                                        font-size: 11px;
                                        line-height: 16px;
                                        text-decoration-line: underline;
                                        color: $dark-grey-100;
                                    }
                                }
                            }
                        }
                    }
                    #login-form {
                        width: 100%;
                        max-width: unset;
                        .form-footer {
                            margin-top: 0;
                            text-align: center;
                            margin-bottom: 1.25rem;
                            #submit-login {
                                min-height: 40px;
                                background: $black;
                                border: 0;
                                color: $white;
                                box-shadow: none;
                                padding: 0 20px;
                            }
                        }
                    }
                    .register-form {
                        .create-account {
                            margin-bottom: 20px;
                            padding-bottom: 20px;
                            text-align: center;
                            border-bottom: 1px solid $light-grey;
                            a {
                                text-decoration-line: underline;
                                line-height: 20px;
                                font-weight: 700;
                                color: $black;
                            }
                        }
                        #customer-form {
                            width: 100%;
                            max-width: unset;
                        }
                        .form-footer {
                            text-align: center;
                            padding-bottom: 2.5rem;
                            border-bottom: 1px solid $light-grey;
                            position: relative;
                            button {
                                min-height: 40px;
                                background: $black;
                                border: 0;
                                color: $white;
                                box-shadow: none;
                                padding: 0 20px;
                                &::before {
                                    content: "";
                                    height: 1px;
                                    background: $light-grey;
                                    width: 50px;
                                    display: block;
                                    position: absolute;
                                    bottom: -1px;
                                    left: -41px;
                                }
                                &::after {
                                    content: "";
                                    height: 1px;
                                    background: $light-grey;
                                    width: 50px;
                                    display: block;
                                    position: absolute;
                                    bottom: -1px;
                                    right: -41px;
                                }
                            }
                        }
                    }
                }
                .page-footer--authentication {
                    background: $white;
                    border-top: 0;
                    padding: 0 2.5rem 2.5rem 2.5rem;
                    text-align: center;
                    border-width: 0px 1px 1px 1px;
                    border-color: $light-grey;
                    border-style: solid;
                    .no-account {
                        padding-bottom: 20px;
                        padding-top: 20px;
                        border-top: 1px solid $light-grey;
                        border-bottom: 1px solid $light-grey;
                        a {
                            color: $black;
                            line-height: 20px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
