body {
    &#category {
        .wrapper_category {
            padding-left: 0rem;
            padding-right: 0rem;
            .block-category {
                min-height: 21.875rem;
                border: 0;
                justify-content: center;
                align-items: center;
                margin-bottom: 2.5rem;
                #_desktop_category_header {
                    background: $white;
                    max-width: 540px;
                    text-align: center;
                    padding: 2.5rem;
                    @include media-breakpoint-down(md) {
                        padding: 20px;
                        max-width: 370px;
                    }
                    h1 {
                        font-weight: 700;
                        font-size: 1.875rem;
                        line-height: 2.5rem;
                        margin-bottom: 1.25rem;
                        text-transform: initial;
                        @include media-breakpoint-down(md) {
                            font-size: 25px;
                            line-height: 33px;
                        }
                    }
                    #category-description {
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        color: $black;
                        #anchor_cat {
                            display: block;
                            padding-top: 20px;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .row_category {
                margin-left: 3.75rem;
                margin-right: 3.75rem;
                margin-bottom: 3.75rem;
                @include media-breakpoint-down(md) {
                    margin-left: 22px;
                    margin-right: 22px;
                }
                #left-column {
                    padding-right: 1.25rem;
                    padding-left: 0;
                    @include media-breakpoint-down(md) {
                        padding-right: 0;
                    }
                }
            }
        }
        .left-column {
            @include media-breakpoint-down(md) {
                padding-right: 0;
                padding-left: 0;
            }

            #subcategories-list {
                display: flex;
                flex-wrap: wrap;
                .category_miniature {
                    width: 19.75rem;
                    height: 22.1875rem;
                    @include media-breakpoint-down(md) {
                        @include make-col(6);
                        // width: 175px;
                        // height: 175px;
                        padding: 0;
                        height: unset;
                        border: 0 solid $light-grey;
                    }
                    background: $white;
                    border: 0.0625rem solid $light-grey;
                    padding: 1.25rem;
                    transition: box-shadow 0.5s;
                    margin-bottom: 20px;
                    .category_img {
                        width: 17.25rem;
                        height: 17.25rem;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            // width: 175px;
                            height: 175px;
                        }
                        overflow: hidden;
                        position: relative;
                        margin-bottom: 0.625rem;
                        img {
                            width: 100%;
                            height: 100%;
                            -o-object-fit: cover;
                            object-fit: cover;
                        }
                    }
                    .category_name {
                        font-size: 1.25rem;
                        line-height: 1.8125rem;
                        color: $black;
                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        &:nth-child(2n) {
                            border-left: 10px solid transparent;
                        }
                        &:nth-child(2n + 1) {
                            border-right: 10px solid transparent;
                        }
                    }
                    @include media-breakpoint-up(md) {
                        &:nth-child(3n + 1) {
                            margin-left: 0;
                            margin-right: 10px;
                        }
                        &:nth-child(3n + 2) {
                            margin-left: 10px;
                            margin-right: 20px;
                            @include media-breakpoint-down(md) {
                                margin-right: 0px;
                            }
                        }
                    }
                    &:hover {
                        box-shadow: $box-shadow;
                    }

                    .btn.slick-arrow {
                        opacity: 0;
                        &.slick-next {
                            right: 15px;
                            padding: 0;
                            @include media-breakpoint-down(md) {
                                display: none !important;
                            }
                            .icons-right {
                                background-image: url("../img/right-miniature.svg");
                                background-size: 12px;
                                background-repeat: no-repeat;
                                background-position: center;
                                @extend .icon;
                            }
                        }
                        &.slick-prev {
                            left: 15px;
                            padding: 0;
                            @include media-breakpoint-down(md) {
                                display: none !important;
                            }
                            .icons-left {
                                background-image: url("../img/left-miniature.svg");
                                background-size: 12px;
                                background-repeat: no-repeat;
                                background-position: center;
                                @extend .icon;
                            }
                        }
                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .icon {
                        width: 40px;
                        height: 40px;
                        display: block;
                        align-items: center;
                        border: 1px solid $white;
                        border-radius: 100%;
                        transition: box-shadow 0.5s;
                        &:hover {
                            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                        }
                    }
                    &:hover {
                        .btn.slick-arrow {
                            opacity: 1;
                        }
                    }
                }
                .no-image-category{
                    height: auto;
                } 
            }
            #js-product-list {
                .products {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: left;
                    margin-left: 0;
                    @include media-breakpoint-down(md) {
                        justify-content: flex-start;
                        margin: 0;
                    }
                    .product-miniature {
                        max-width: 316px;
                        .card-product.card {
                            @include media-breakpoint-down(md) {
                                min-width: 100%;
                                height: 100%;
                            }
                            .card-img-top {
                                @include media-breakpoint-down(md) {
                                    width: 100%;
                                    height: 175px;
                                }
                            }
                        }
                        @include media-breakpoint-down(md) {
                            max-width: unset;
                            min-height: auto;
                            @include make-col(6);
                            padding: 0;
                            height: unset;
                            border: 0 solid $light-grey;
                        }

                        @include media-breakpoint-down(md) {
                            &:nth-child(2n) {
                                border-left: 10px solid transparent;
                            }
                            &:nth-child(2n + 1) {
                                border-right: 10px solid transparent;
                            }
                        }
                        @include media-breakpoint-up(md) {
                            &:nth-child(3n + 1) {
                                margin-left: 0;
                                margin-right: 20px;
                            }
                            &:nth-child(3n + 2) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
                .page-list {
                    &.pagination {
                        display: flex;
                        align-items: center;
                        .page-item {
                            border: 0.0625rem solid $light-grey;
                            line-height: 20px;
                            margin: 0 5px 20px 5px;
                            cursor: pointer;
                            min-height: 2.5rem;
                            min-width: 1.875rem;
                            transition: box-shadow 0.5s;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            line-height: 20px;
                            color: $dark-grey-100;
                            &.next {
                                border: 0;
                            }
                            &.prev {
                                border: 0;
                            }
                            a {
                                &.page-link {
                                    border: 0;
                                    padding: 0;
                                    background: none;
                                    color: $black;
                                    box-shadow: none;
                                }
                                &.next {
                                    color: $dark-grey-100;
                                }
                                &.prev {
                                    color: $dark-grey-100;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* SECTION SEO CAT */
        .section_seo_cat {
            margin-bottom: 60px;
            padding: 0 160px;
            width: 100%;
            @include media-breakpoint-down(md) {
                padding: 0;
                margin-bottom: 40px;
            }
            .container {
                padding-right: 10px;
                padding-left: 10px;
                .seo_row {
                    margin-right: -10px;
                    margin-left: -10px;
                    .seo_col {
                        padding-right: 10px;
                        padding-left: 10px;
                        h2 {
                            font-weight: 700;
                            font-size: 25px;
                            line-height: 33px;
                            color: $black;
                            margin-bottom: 20px;
                            @include media-breakpoint-down(md) {
                                font-size: 22px;
                                line-height: 29px;
                            }
                        }
                        .seo {
                            font-size: 14px;
                            line-height: 20px;
                            color: $black;
                        }
                        .seo_img {
                            width: 540px;
                            height: 540px;
                            @include media-breakpoint-down(md) {
                                width: 100%;
                                height: 414px;
                            }
                            @media (min-width: 1200px) and (max-width: 1440px) {
                                width: 100%;
                            }
                            @include media-breakpoint-between(md, lg) {
                                width: 100%;
                                height: auto;
                            }

                            overflow: hidden;
                            position: relative;
                            img {
                                width: 100%;
                                height: 100%;
                                -o-object-fit: cover;
                                object-fit: cover;
                            }
                        }
                        &:first-child {
                            @include media-breakpoint-down(md) {
                                padding: 0;
                                margin-bottom: 40px;
                            }
                        }
                        &:last-child {
                            @include media-breakpoint-down(md) {
                                padding: 0 22px;
                            }
                        }
                    }
                }
            }
        }
        #show-sub-category{
            text-decoration: underline;
        }
    }
}

/* -------------- */
