.menu {
  padding-bottom: $spacer;
}
.menu-top {
  display: flex;
  justify-content: center;
  margin: 0;
  position: relative;
  li {
    &.menu__item--top {
      margin: 0 10px;
      padding: 10px 20px;
      .get_sub_menu[aria-expanded="true"] {
        transform: rotate(0);
      }
      @include media-breakpoint-down(md) {
        margin: 0;
        padding: 0px;
        position: relative;
      }
      &:hover {
        background: $ultra-light-grey;
      }
      &:first-child {
        @include media-breakpoint-up(sm) {
          margin-left: 0;
        }
      }
      &:last-child {
        @include media-breakpoint-up(sm) {
          margin-right: 0;
        }
      }
      .menu__item-header {
        @include media-breakpoint-down(md) {
          padding: 0 10px;
          min-height: 40px;
          align-items: center;
          width: 100%;
        }
      }

      &.menu__item--active {
        .menu__item-link--hassubmenu {
          transition-delay: 0.5s;
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  a.menu__item-link--top {
    color: $dark-grey-100;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    align-items: center;

    // text-transform: uppercase;
    // display: block;
    // padding: $spacer/2;
    // @include media-breakpoint-down(md) {
    //   color: $black;
    // }

    &.menu__item-link--hassubmenu {
      span {
        padding-right: 15px;
      }
      // &:hover{
      //   &::after{
      //     transform: rotate(180deg);
      //   }
      &::after {
        @include media-breakpoint-up(md) {
          background: url("../img/has-submenu.svg");
          background-repeat: no-repeat;
          background-size: 12px 6px;
          content: "";
          width: 12px;
          height: 6px;
          display: inline-block;
          transition-duration: 0.5s;
        }
      }
    }
  }
}

@include media-breakpoint-desktop {
  .menu__item-header {
    height: 100%;
  }
  .menu-sub {
    position: absolute;
    opacity: 0;
    // width: 100%;
    // left: 0;
    left: unset;
    margin-left: -20px;

    width: fit-content;
    margin-top: 10px;

    top: 100%;
    z-index: -1;
    transition: all 0.5s ease;
  }
  .menu-sub__content > ul {
    padding: 0px;
    background-color: #fff;
    box-shadow: $box-shadow;
  }
  .menu__item--active .menu-sub {
    z-index: 2;
    opacity: 1;
    width: fit-content;
    width: -moz-fit-content;
  }
  .menu-sub__list--1 {
    display: flex;
    flex-direction: column;
  }
  .menu__item--1 {
    padding-right: 10px;
    padding-left: 10px;
    min-height: 40px;
    border-bottom: 1px solid $light-grey;
    display: flex;
    align-items: center;
    min-width: 190px;
    &:hover {
      background: $ultra-light-grey;
    }

    &::after {
      background: url("../img/item-submenu.svg");
      background-repeat: no-repeat;
      background-size: 7px 10px;
      content: "";
      width: 7px;
      height: 10px;
      display: inline-block;
    }
  }
  a.menu__item-link--1 {
    // text-transform: uppercase;
    color: $dark-grey-100;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    &a {
      color: $dark-grey-100;
      &:hover {
        color: $dark-grey-100;
      }
    }
    .menu__item-link {
      color: $body-color;
    }
  }
}

//mobile
@include media-breakpoint-mobile() {
  .menu-top {
    flex-direction: column;
    margin-bottom: $spacer;
  }
  .menu-sub {
    width: 100%;
    opacity: 0;
    // transition: all 0.3s ease-out;
  }
  //remove padding from container class
  .menu-sub__content {
    padding: 0;
  }
  .menu__item-link--top {
    padding: 0;
  }
  .top-menu-mobile {
    margin-right: -$modal-inner-padding;
    margin-left: -$modal-inner-padding;
    .cta.goback {
      margin: 0 15px 20px 15px;
      width: max-content;
    }
  }
  .menu__item {
    //padding: $spacer/2;
    border-bottom: 1px solid $light-grey;
    border-top: 1px solid $light-grey;
    min-height: 40px;
    display: flex;
    align-items: center;
    .menu-sub {
      a {
        @include media-breakpoint-down(md) {
          color: $black;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &::after {
          // background: url("../img/item-right.svg");
          background-repeat: repeat;
          background-size: auto;
          background-repeat: no-repeat;
          background-size: 7px 10px;
          content: "";
          width: 23px;
          height: 10px;
          display: inline-block;
        }
      }
    }
    &:hover {
      @include media-breakpoint-down(md) {
        background: $ultra-light-grey;
      }
    }
  }
  .menu__item--top:not(:first-child) {
    border-top: 0;
  }
  .menu__item--sub:last-child,
  .menu__item--sub:first-child:not(:last-child) {
    border-bottom: 0;
  }
  .menu__item-header {
    display: flex;
    justify-content: space-between;
    padding: $menu-item-padding;
  }
  .menu__item-link--nosubmenu {
    padding: $menu-item-padding;
    display: block;
  }
  .menu-sub.show {
    background: $white;
    @include media-breakpoint-down(md) {
      position: absolute;
      top: -1px;
    }
  }

  //increase offset
  .menu__item--1 {
    .menu__item-link--nosubmenu,
    .menu__item-header {
      margin-left: 10px;
    }
  }
  .menu__item--2 {
    .menu__item-link--nosubmenu,
    .menu__item-header {
      margin-left: 20px;
    }
  }
  .menu__item--3 {
    .menu__item-link--nosubmenu,
    .menu__item-header {
      margin-left: 30px;
    }
  }
}

#mobile_top_menu_wrapper {
  &.modal {
    .modal-dialog__offcanvas {
      width: 100%;
      max-width: 100%;
    }
    .modal-header {
      border-bottom: 0;
      padding: 20px;
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        .mobile_top_menu {
          width: 100%;
          display: flex;
          align-items: center;

          .user-info {
            display: block;
            .myaccount {
              background-image: url("../img/my-account.svg");
              background-size: 16px 20px;
              background-repeat: no-repeat;
              background-position: center;
              width: 40px;
              height: 40px;
              display: block;
              align-items: center;
              border: 1px solid $light-grey;
              border-radius: 100%;
              transition: box-shadow 0.5s;
              &:hover {
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
              }
            }
          }
          .shopping-cart {
            position: relative;
            .header_panier {
              background-image: url("../img/toppanier.svg");
              background-size: 20px;
              background-repeat: no-repeat;
              background-position: center;
              width: 40px;
              height: 40px;
              display: block;
              align-items: center;
              border: 1px solid $light-grey;
              border-radius: 100%;
              transition: box-shadow 0.5s;
              &:hover {
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
              }
            }

            .blockcart__count {
              background: $black;
              color: $white;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              position: absolute;
              right: -5px;
              bottom: -10px;
          }

          }
          .close {
            opacity: 1;
            .close_menu {
              background-image: url("../img/close-menu.svg");
              background-size: 20px;
              background-repeat: no-repeat;
              background-position: center;
              width: 40px;
              height: 40px;
              display: block;
              align-items: center;
              border: 1px solid $black0;
              border-radius: 100%;
              transition: box-shadow 0.5s;
              &:hover {
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
        .header__search {
          margin-top: 20px;
          .search-widget__input-right {
            min-height: 2.5rem;
            border: 0.0625rem solid $light-grey;
            box-shadow: none;
            padding-left: 20px;
            transition: box-shadow 0.5s;
            &:hover {
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            }
          }
          .search-widget__btn {
            padding: 10px 20px;
            margin: 0;
            border: none;
            .loop {
              background-image: url("../img/loop.svg");
              background-size: 20px;
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }
      }
    }
    .modal-body {
      padding-top: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.menu__collapseicon {
  width: 40px;
  height: 40px;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
  background: url("../img/item-right.svg");
  background-repeat: repeat;
  background-size: auto;
  background-repeat: no-repeat;
  background-size: 7px 10px;
  background-position: right;
  // position: absolute;
  // right: 15px;
  // top: 15px;
}

a {
  &.cta {
    &.goback {
      padding: 10px 20px 10px 27px;
      &::after {
        display: none;
      }
      &:before {
        content: "";
        background-image: url("../img/icon-left.svg");
        background-size: 6px 12px;
        background-repeat: no-repeat;
        width: 6px;
        height: 12px;
        display: inline-block;
        position: relative;
        top: 0px;
        left: 0;
        margin-right: 17px;
      }
    }
  }
}

.fade-in {
  animation: fadeIn 0.5s;
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.collapsing {
  transition: none !important;
}
