.l-footer {
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
  .bg-footer {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 15px;
    background-color: black;
  }
}
