body:not(#index) {
    @include media-breakpoint-down(sm) {
        .just-hp {
            display: none;
        }
    }

    .card--reassurance {
        border: 0;
        margin: 0 60px 60px;
        padding: 60px 52px;
        @include media-breakpoint-down(md) {
            margin: 0 -15px;
            padding: 22px;
        }
        background-image: url(../img/rea_bg.svg);
        background-size: cover;
        background-repeat: no-repeat;
        .content__reassurance {
            padding: 40px 130px;
            @include media-breakpoint-down(md) {
                padding: 20px;
            }
            background: $white;
            .rea_titre {
                font-weight: bold;
                font-size: 22px;
                line-height: 32px;
                text-align: center;
                margin-bottom: 20px;
                display: block;
                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 29px;
                }
            }
            .rea_descr {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                margin-bottom: 40px;
                display: block;
            }

            ul.list-group {
                display: flex;
                flex-flow: wrap;
                li.list-group-item {
                    border: 0;
                    padding: 0 20px;
                    @include media-breakpoint-down(md) {
                        padding: 0;
                        @include make-col(6);
                    }
                    @include make-col(3);
                    background-color: transparent;
                    padding: 0 10px;

                    @include media-breakpoint-down(md) {
                    &:nth-child(2n + 1) {
                        padding-right: 10px;
                    }
                    &:nth-child(2n + 2) {
                        padding-left: 10px;
                    }
                    &:nth-last-child(1) {
                        .r_descr {
                            margin-bottom: 0px;
                        }
                    }
                    &:nth-last-child(2) {
                        .r_descr {
                            margin-bottom: 0px;
                        }
                    }
                }


                    .r_img {
                        width: 40px;
                        height: 40px;
                        margin: 0 auto 20px auto;
                    }
                    .r_title {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 29px;
                        @media (min-width: 1200px) and (max-width: 1440px) {
                            font-size: 18px;
                            line-height: 26px;
                            margin-bottom: 10px;
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 18px;
                            line-height: 26px;
                        }
                        text-align: center;
                        color: $black;
                        margin-bottom: 20px;
                        display: block;
                    }
                    .r_descr {
                        text-align: center;
                        font-size: 14px;
                        line-height: 20px;
                        display: block;
                    }
                }



                





            }
        }
    }
}
.prestablog {
    &.home_prestablog {
        // max-width: 1320px;
        padding: 0 60px;
        margin: 0 auto 60px auto;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .title_home_blog {
            font-weight: 700;
            font-size: 25px;
            line-height: 33px;
            text-align: center;
            color: $black;
            margin-bottom: 20px;
            text-transform: initial;
        }
        .descrp_home_blog {
            font-size: 22px;
            line-height: 32px;
            font-weight: 400;
            text-align: center;
            color: $black;
            margin-bottom: 40px;
        }
        .cta {
            margin: 0 auto;
        }
        ul {
            &.liste_articles {
                padding: 0 50px;
                margin-bottom: 40px;
                @include media-breakpoint-down(md) {
                    padding: 0 0 0 10px;
                }
                li {
                    margin: 0 10px;
                    .block_cont {
                        width: 285.5px;
                        @include media-breakpoint-down(md) {
                            width: 200px;
                        }
                        .block_top {
                            height: 200px;
                            width: 285.5px;
                            @include media-breakpoint-down(md) {
                                height: 160px;
                                width: 200px;
                                margin-bottom: 10px;
                            }
                            overflow: hidden;
                            margin-bottom: 20px;
                            img {
                                width: 100%;
                                height: 100%;
                                -o-object-fit: cover;
                                object-fit: cover;
                            }
                        }
                        .block_bas {
                            h3 {
                                font-family: $font-family-sans-serif;
                                text-align: center;
                                margin-bottom: 20px;
                                @include media-breakpoint-down(md) {
                                    margin-bottom: 10px;
                                }
                                a {
                                    font-weight: 700;
                                    font-size: 20px;
                                    line-height: 29px;
                                    color: $dark-grey-100;
                                    @include media-breakpoint-down(md) {
                                        font-size: 18px;
                                        line-height: 26px;
                                    }
                                }
                            }
                            .blog_desc {
                                font-size: 14px;
                                line-height: 20px;
                                text-align: center;
                                color: $black;
                                margin: 0;
                            }
                        }
                    }
                }

                .btn {
                    box-shadow: none !important;
                    &.slick-next {
                        right: 0;
                        padding: 0;
                        @include media-breakpoint-down(md) {
                            display: none !important;
                        }
                        .icons-right {
                            background-image: url("../img/right.svg");
                            background-size: 12px;
                            background-repeat: no-repeat;
                            background-position: center;
                            @extend .icon;
                        }
                    }
                    &.slick-prev {
                        left: 0;
                        padding: 0;
                        @include media-breakpoint-down(md) {
                            display: none !important;
                        }
                        .icons-left {
                            background-image: url("../img/left.svg");
                            background-size: 12px;
                            background-repeat: no-repeat;
                            background-position: center;
                            @extend .icon;
                        }
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
                .icon {
                    width: 40px;
                    height: 40px;
                    display: block;
                    align-items: center;
                    border: 1px solid $black0;
                    border-radius: 100%;
                    transition: box-shadow 0.5s;
                    &:hover {
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}
