body#password {
    .row_password {
        background: url(../img/bg-cnx.svg);
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 3.75rem;
        padding-top: 3.75rem;
        @include media-breakpoint-down(md) {
            padding-top: 2.5rem;
        }
        #content-wrapper {
            padding-right: 22px;
            padding-left: 22px;
            .page-wrapper--password {
                background: none;
                border: 0;
                padding-bottom: 3.75rem;
                @include media-breakpoint-down(md) {
                    padding-bottom: 2.5rem;
                }
                margin: 0 auto;
                max-width: 650px;
                .page-header--password {
                    padding: 0;
                    background: none;
                    border: 0;
                    margin-bottom: 1.25rem;
                    h1 {
                        font-weight: 700;
                        font-size: 1.875rem;
                        line-height: 2.5rem;
                        text-align: center;
                        text-transform: unset;
                        margin-bottom: 20px;
                        @include media-breakpoint-down(md) {
                            font-size: 25px;
                            line-height: 33px;
                        }
                    }
                    .send-renew-password-link {
                        line-height: 1.25rem;
                        text-align: center;
                        p {
                            margin: 0;
                        }
                    }
                }
                .page-content--password {
                    background: $white;
                    padding: 2.5rem 2.5rem 0rem 2.5rem;
                    border-width: 1px 1px 0px 1px;
                    border-color: $light-grey;
                    border-style: solid;
                    .form-fields {
                        .form-group {
                            margin-bottom: 20px;
                            label {
                                line-height: 20px;
                                margin-bottom: 10px;
                                position: relative;
                                &.required {
                                    &::after {
                                        content: "*";
                                        color: $wrong;
                                        position: absolute;
                                    }
                                }
                            }
                            .form-control {
                                box-shadow: none;
                            }
                        }
                    }
                    .forgotten-password {
                        width: 100%;
                        max-width: unset;
                        .form-control-submit {
                            min-height: 40px;
                            background: $black;
                            border: 0;
                            color: $white;
                            box-shadow: none;
                            padding: 0 20px;
                            margin: 0 auto 20px auto;
                            display: block;
                        }
                    }
                }
                .page-footer--password {
                    background: $white;
                    border-top: 0;
                    padding: 0 2.5rem 2.5rem 2.5rem;
                    text-align: center;
                    border-width: 0px 1px 1px 1px;
                    border-color: $light-grey;
                    border-style: solid;
                    .account-link {
                        padding-bottom: 20px;
                        padding-top: 20px;
                        border-top: 1px solid $light-grey;
                        border-bottom: 1px solid $light-grey;
                        color: $black;
                        line-height: 20px;
                        display: block;
                    }
                    ul {
                        margin-bottom: 0;
                        a {
                            &:not(.account-link) {
                                padding-bottom: 20px;
                                padding-top: 20px;
                                border-top: 1px solid $light-grey;
                                border-bottom: 1px solid $light-grey;
                                color: $black;
                                line-height: 20px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
