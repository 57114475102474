body {
  &#checkout {
    input {
      &.form-control {
        width: 70%;
      }
    }
    select {
      &.custom-select {
        width: 70%;
        display: block;
      }
    }
  }
  &#cart {
    .pw-fav-slider {
      .product-miniature {
        min-height: 633px;
      }
    }
  }
}
